import React from "react";

import { concatClassNames as cn } from "@sys42/utils";

import { HighlightedText } from "@userbrain/website-ui";

import Layout from "../layouts/layout";

import { ContentCentered } from "../components/ContentCentered";
import { GetStartedBox } from "../components/GetStartedBox";
import { HeaderTitle } from "../components/HeaderTitle";
import { ImageSlider } from "../components/ImageSlider";
import { Intro } from "../components/Intro";
import { WavySeparator } from "../components/WavySeparator";

import * as styles from "./styles/about.module.css";
import * as helperStyles from "./helpers.module.css";

import imageThumbUp from "../images/contentCenteredThumbUp.jpg";

import imageSlider1 from "../images/imageSliderTeam.jpg";
import imageSlider2 from "../images/iamgeSliderPostIts.jpg";
import imageSlider3 from "../images/imageSliderPeopleWorkingTogether.jpg";

import imageIntroRobotHand from "../images/introRoboticHand.jpg";
import imageIntroDashboardSessions from "../images/introDashboardSessions.jpg";

export default function About() {
  return (
    <Layout
      title={"About"}
      metaDescription={
        "Get to know more about why we built Userbrain and the Founders behind it: Markus Pirker, Mathias Placho, Stefan Rössler, and Andreas Riedmüller."
      }
    >
      <article>
        <HeaderTitle overline={"About Userbrain"} titleWidth={"12.6em"}>
          <span className={helperStyles.h1Serif}>
            We want you to build products people
          </span>{" "}
          love to use.
        </HeaderTitle>

        <div
          className={cn(helperStyles.spacingMd, helperStyles.containerColor)}
        >
          <ContentCentered
            className={styles.contentCentered}
            imageSrc={imageThumbUp}
            imageAlt={"Hand coming through a whole showing thumbs up"}
            textContent={
              "Every 2nd company fails because it develops a product that nobody needs. Why? Because they forget to include those outside their four walls. We want you to test ideas — fast, often, and imperfectly. So you end up with a product people love to use."
            }
          />
        </div>

        <WavySeparator style={{ paddingTop: "var(--spacing-md)" }} />

        <div className={helperStyles.container}>
          <ContentCentered
            className={styles.aDecadeOfUx}
            title={"Userbrain team: over a decade of UX expertise."}
            titleWidth={"13em"}
            textContent={
              "Userbrain was founded by Markus Pirker, Mathias Placho, Stefan Rössler, and Andreas Riedmüller — a team of UX experts and designers who have been consulting start-ups and big-brand clients with their company Simplease, for over ten years. Time and time again, they were faced with a need for a tool that can carry out fast, simple, and affordable user tests. A product they couldn’t find — so they built it. "
            }
          />
        </div>

        <ImageSlider
          className={helperStyles.spacingLg}
          images={[
            {
              src: imageSlider1,
              alt: "The four founders of Userbrain",
            },
            {
              src: imageSlider2,
              alt: "Post Its on a wall and a woman touching one with her hand",
            },
            {
              src: imageSlider3,
              alt: "People working together happily",
            },
          ]}
        />

        <div className={helperStyles.container}>
          <Intro
            className={styles.intro}
            title={"A simple yet powerful user testing tool."}
            titleWidth={"12em"}
            content={
              "Together we’ve built an online tool, backed by thousands of testers, that will let you know what’s working for your product — and what’s not. We set out to empower UX designers, researchers, and managers to build products people love to use."
            }
            linkTo={"/features"}
            linkText={"Find out more"}
            image1Src={imageIntroDashboardSessions}
            image1Alt={"New sessions in the Userbrain dashboard"}
            image2Src={imageIntroRobotHand}
            image2Alt={"Robotic hand on red background"}
          />

          <GetStartedBox
            className={styles.getStartedBox}
            title={
              <>
                Want to <HighlightedText cursor>touch base</HighlightedText>?
              </>
            }
            contactUs
          />
        </div>
      </article>
    </Layout>
  );
}
