import React from "react";
import { concatClassNames as cn } from "@sys42/utils";
// import { } from '../../system42'
import * as styles from "./styles.module.css";

export function ImageSlider(props) {
  const { className, images, ...containerProps } = props;

  return (
    <div className={cn(className, styles.imageSlider)} {...containerProps}>
      {images.map((item, index) => (
        <img key={index} src={item.src} alt={item.alt} />
      ))}
    </div>
  );
}
